import React from 'react';

import { Product } from 'utils/helpers/product';
import { SponsoredBrandQuery } from 'src/utils/ads/types';

import { HybridCarousel, HybridCarouselCard } from 'src/components/hybrid-carousel';
import { CarouselStates } from 'src/components/hybrid-carousel/use-hybrid-carousel-rendered';
import { CarouselViewAll } from '../../carousel-view-all';

import { useSponsoredBrandCarousel } from './use-sponsored-brand-carousel';

type SponsoredBrandCarouselProps = {
  fullWidth?: boolean;
  onRender?: (state: CarouselStates) => void;
  sponsoredBrandQuery: SponsoredBrandQuery;
};

export const SponsoredBrandCarousel = ({
  fullWidth = false,
  onRender,
  sponsoredBrandQuery,
}: SponsoredBrandCarouselProps): JSX.Element | null => {
  const {
    brandName,
    brandHref,
    products,
    loading,
    error,
    theme,
    dimensions,
    handleProductClick,
    handleQuickAddToCart,
    handleProductImpression,
    handleViewAllClick,
    viewportVisibility,
  } = useSponsoredBrandCarousel({
    sponsoredBrandQuery,
  });

  if (!products.length || error) {
    return null;
  }

  if (!brandName) {
    console.error('missing brandName');
    return null;
  }

  const carouselProps = {
    carouselTheme: theme,
    dimensions,
    fullWidth,
    heading: brandName,
    href: brandHref,
    margin: 0,
    linkLabel: `View All`,
    loading,
    subheading: `Sponsored`,
    viewportVisibility,
  };

  return (
    <div data-testid='sponsored-brand-carousel'>
      <HybridCarousel {...carouselProps} onClickHeaderLink={handleViewAllClick} onRender={onRender}>
        {products.map((product: Product, index) => (
          <HybridCarouselCard
            carouselTheme={theme}
            dimensions={dimensions}
            key={product.id}
            onClick={() => handleProductClick(product, index)}
            onQuickAddClick={() => handleQuickAddToCart(product, index)}
            onView={() => handleProductImpression(product)}
            product={product}
            productIndex={index}
            filledQuickAdd
            hideSponsoredTags
          />
        ))}
        <CarouselViewAll href={brandHref} />
      </HybridCarousel>
    </div>
  );
};
