import React, { useCallback, useRef } from 'react';
import Truncate from 'react-truncate';
import styled from 'styled-components';
import TinyColor from 'tinycolor2';
import Link from 'next/link';

import { Product } from 'types/graphql-overrides';
import { Typography } from 'src/typography';

import ProductImage from 'src/components/product-image';

import { useCard } from 'src/components/product-card-v2/use-card';
import { VisuallyHidden } from 'src/components/core/visually-hidden';
import { PlusIcon } from 'src/assets/plus';

export type BuyItAgainListItemProps = {
  product: Product;
  productIndex: number;
  onClick: () => void;
  onQuickAddClick: () => void;
};

export const BuyItAgainListItem = ({
  product,
  onClick,
  onQuickAddClick,
}: BuyItAgainListItemProps): JSX.Element | null => {
  const cardRef = useRef<HTMLElement | null>(null);

  const handleClick = useCallback((): void => {
    onClick();
  }, [onClick]);

  const handleQuickAddClick = useCallback((): void => {
    onQuickAddClick();
    onClick();
  }, [onQuickAddClick, onClick]);

  const { href, strainType, potency, hideDiscount, priceOptions } = useCard({ product });

  const detailsString = `${strainType ? `${strainType} - ` : ''}${potency.join(' · ')}`;
  const { formattedPrice, formattedDiscount, special } = priceOptions[0];
  const displayPrice = special ? special.formattedPrice : formattedPrice;
  const standardPrice = special ? formattedPrice : null;
  const discount = special && !hideDiscount ? formattedDiscount : null;

  return (
    <Wrapper
      aria-label={product.name}
      aria-roledescription='list-item'
      data-testid='buy-it-again-list-item'
      ref={cardRef}
    >
      <Link href={href}>
        <Anchor href={href} onClick={handleClick}>
          <ProductImage width={48} height={48} product={product} />

          <Content>
            <Name size='medium' tag='div'>
              <Truncate>{product.name}</Truncate>
            </Name>

            {detailsString && <Details>{detailsString}</Details>}

            <Price>
              <ActivePrice>{displayPrice}</ActivePrice>

              {discount && (
                <DiscountedPrice>
                  <OriginalPrice>{standardPrice}</OriginalPrice>
                  <Discount>{discount}</Discount>
                </DiscountedPrice>
              )}
            </Price>
          </Content>
        </Anchor>
      </Link>

      <QuickAddButton onClick={handleQuickAddClick}>
        <VisuallyHidden>Add {product.name} to cart</VisuallyHidden>
        <PlusIcon />
      </QuickAddButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
  padding: 8px 0;

  /* The last child of the container is the IO watcher div, so we need to target the last Item */
  &:has(+ :not(&)) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const Anchor = styled.a`
  align-items: center;
  display: flex;
  gap: 16px;
  width: calc(100% - 52px);
`;

const Content = styled.div`
  flex: 1;
`;

const Name = styled(Typography.Title)`
  color: #121516;
`;

const Details = styled.div`
  color: #646d72;
  display: flex;
  font: 11px / ${12 / 11} ${({ theme }) => theme.customized.fonts.secondary};
  margin-top: 2px;
  gap: 1ch;
`;

const Price = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
  margin-top: 6px;
`;

const ActivePrice = styled.div`
  color: #2c3236;
  font-size: 16px;
  font-weight: 600;
`;

const DiscountedPrice = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;

const OriginalPrice = styled.div`
  color: #485055;
  font-size: 11px;
  text-decoration: line-through;
`;

const Discount = styled.div`
  background: ${({ theme }) => theme.customized.colors.discountTag};
  border-radius: ${({ theme }) => theme.customized.radius.tags};
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
`;

const QuickAddButton = styled.button`
  align-items: center;
  appearance: none;
  /* Hex alpha */
  background: ${({ theme }) => theme.customized.colors.buttonsLinks}1a;
  border-radius: 50%;
  border: none;
  color: ${({ theme }) => TinyColor.mix(theme.customized.colors.buttonsLinks, '#000000', 20).toString()};
  cursor: pointer;
  display: flex;
  flex: 0 0 36px;
  height: 36px;
  justify-content: center;
  padding: 0;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.customized.colors.buttonsLinks};
    color: #fff;
  }
`;
