import React from 'react';

import { Product } from 'src/utils/helpers/product';
import { useCardDimensions } from 'src/components/product-card/product-card.utils';

import { HybridSlider } from 'src/components/hybrid-carousel/hybrid-carousel-slider';
import { HybridCarouselCard } from 'src/components/hybrid-carousel';

type TabSliderProps = {
  products: Product[];
  viewAllCard?: React.ReactNode;
  onQuickAddToCart: (product: Product, index: number) => void;
  onProductClick: (product: Product, index: number) => void;
  onView?: (product: Product, index: number) => void;
};

export const TabSlider = ({
  products = [],
  onProductClick,
  onView,
  onQuickAddToCart,
  viewAllCard,
}: TabSliderProps): JSX.Element => {
  const dimensions = useCardDimensions({ gutter: 12 });

  return (
    <HybridSlider fullWidth>
      {products.map((product: Product, index) => (
        <HybridCarouselCard
          dimensions={dimensions}
          key={product.id}
          product={product}
          productIndex={index}
          onView={() => onView?.(product, index)}
          onClick={() => onProductClick(product, index)}
          onQuickAddClick={() => onQuickAddToCart(product, index)}
        />
      ))}
      {viewAllCard ?? null}
    </HybridSlider>
  );
};
