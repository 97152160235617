import React from 'react';

import { Product } from 'src/utils/helpers/product';

import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';

type TabSliderProps = {
  products: Product[];
  viewAllCard?: React.ReactNode;
  onQuickAddToCart: (product: Product, index: number) => void;
  onProductClick: (product: Product, index: number) => void;
  onProductView?: (product: Product, index: number) => void;
  isLoading: boolean;
};

export const TabSlider = ({
  products = [],
  onProductClick,
  onProductView,
  onQuickAddToCart,
  viewAllCard,
  isLoading,
}: TabSliderProps): JSX.Element => (
  <ProductCarousel
    products={products}
    isLoading={isLoading}
    hideTitle
    hideLink
    onProductClick={onProductClick}
    onQuickAddToCart={onQuickAddToCart}
    onProductView={onProductView}
    afterProducts={viewAllCard}
    mt='0'
  />
);
