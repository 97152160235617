import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { PersonalizedProductsQuery, RecommendedProductsQuery } from 'src/personalization/data-layer/types';
import { SponsoredBrandQuery } from 'src/utils/ads/types';

import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';
import { usePersonalizedProductsCarousel } from '../../use-personalized-products-carousel';
import { useBuyItAgainProducts } from '../../use-buy-it-again-products';
import { BuyItAgain } from './buy-it-again';

type BlendedPersonalizedProductCarouselProps = {
  heading?: string;
  icon?: React.ReactNode;
  personalizedProductsQuery: PersonalizedProductsQuery;
  recommendedProductsQuery: RecommendedProductsQuery;
  sponsoredBrandQuery?: SponsoredBrandQuery;
  subheading?: string;
};

export const BlendedPersonalizedProductCarousel = ({
  heading,
  icon,
  personalizedProductsQuery,
  recommendedProductsQuery,
  sponsoredBrandQuery,
  subheading,
}: BlendedPersonalizedProductCarouselProps): JSX.Element | null => {
  const flags = useFlags();
  const buyItAgainProductsEnabled = flags['growth.ads.buy-it-again-carousel.rollout'];

  const {
    handleProductClick,
    handleProductImpression,
    handleQuickAddToCart,
    products,
    theme,
    error,
    viewportVisibility,
  } = usePersonalizedProductsCarousel({ personalizedProductsQuery, sponsoredBrandQuery, maxSponsoredProducts: 3 });

  const buyItAgainProducts = useBuyItAgainProducts({
    recommendedProductsQuery,
  });
  const { loading: buyItAgainLoading, error: buyItAgainError } = buyItAgainProducts;

  if (buyItAgainError) {
    console.error("Error fetching user's previously purchased products.", buyItAgainError);
  }

  const isLoading = products.length > 0 && buyItAgainLoading;

  if (error || !products.length) {
    return null;
  }

  return (
    <div data-testid='personalized-products-carousel'>
      <ProductCarousel
        ref={viewportVisibility.ref}
        products={products}
        isLoading={isLoading}
        title={heading}
        subTitle={subheading}
        icon={icon}
        beforeProducts={buyItAgainProductsEnabled ? <BuyItAgain theme={theme} {...buyItAgainProducts} /> : null}
        onProductClick={handleProductClick}
        onProductView={handleProductImpression}
        onQuickAddToCart={handleQuickAddToCart}
      />
    </div>
  );
};
