/* eslint-disable @typescript-eslint/naming-convention */

import React, { useCallback, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { PersonalizedProductsQuery, RecommendedProductsQuery } from 'src/personalization/data-layer/types';
import { SponsoredBrandQuery } from 'src/utils/ads/types';

import EmptyLoadingState from 'src/components/empty-loading-state';
import { Content } from 'src/components/hybrid-carousel/hybrid-carousel.styles';
import { HybridHeader } from 'src/components/hybrid-carousel/hybrid-carousel-header';

import { usePersonalizedProductsCarousel } from '../../use-personalized-products-carousel';
import { useBuyItAgainProducts } from '../../use-buy-it-again-products';

import { BuyItAgainViewAll } from './buy-it-again-view-all';
import { TabSlider } from './tab-slider';

import {
  StyledCarouselContainer,
  StyledTabContext,
  StyledTabs,
  StyledTab,
  StyledTabPanel,
} from './tabbed-carousel.styles';

type TabbedPersonalizedProductCarouselProps = {
  heading?: string;
  icon?: React.ReactNode;
  personalizedProductsQuery: PersonalizedProductsQuery;
  recommendedProductsQuery: RecommendedProductsQuery;
  sponsoredBrandQuery?: SponsoredBrandQuery;
  subheading?: string;
};

const TABS = {
  TOP_PICKS: 'top-picks',
  BUY_IT_AGAIN: 'buy-it-again',
};
type Tabs = typeof TABS[keyof typeof TABS];

const TabProps = (key: Tabs, selectedTab: Tabs): Record<string, boolean | string> => {
  const id = `${key}-tab`;
  return {
    'aria-controls': `${key}-tab-panel`,
    'aria-selected': selectedTab === key,
    'data-testid': id,
    id,
    value: key,
  };
};

const TabPanelProps = (key: Tabs): Record<string, string> => {
  const id = `${key}-tab-panel`;
  return {
    'aria-labelledby': `${key}-tab`,
    'data-testid': id,
    id,
    value: key,
  };
};

export const TabbedPersonalizedProductCarousel = ({
  heading,
  icon,
  personalizedProductsQuery,
  recommendedProductsQuery,
  sponsoredBrandQuery,
  subheading,
}: TabbedPersonalizedProductCarouselProps): JSX.Element | null => {
  const flags = useFlags();
  const [selectedTab, setSelectedTab] = useState(TABS.TOP_PICKS);

  const personalizationCarouselPlacement = flags['growth.ads.personalization-carousel.placement'];
  const buyItAgainProductsEnabled = flags['growth.ads.buy-it-again-carousel.rollout'];
  const margin = personalizationCarouselPlacement === 'secondary' ? 10 : 40;

  const {
    handleProductClick,
    handleProductImpression,
    handleQuickAddToCart,
    products,
    theme,
    error,
    viewportVisibility: { ref: topPicksRef },
  } = usePersonalizedProductsCarousel({ personalizedProductsQuery, sponsoredBrandQuery, maxSponsoredProducts: 3 });

  const {
    products: buyItAgainProducts,
    loading: buyItAgainLoading,
    error: buyItAgainError,
    handleProductClick: handleBuyItAgainProductClick,
    handleQuickAddToCart: handleBuyItAgainQuickAddToCart,
    handleViewAllClick,
    viewportVisibility: { ref: buyItAgainRef },
  } = useBuyItAgainProducts({
    recommendedProductsQuery,
  });

  const handleTabChange = useCallback(
    (_, newValue) => {
      if (selectedTab !== newValue) {
        setSelectedTab(newValue);
      }
    },
    [selectedTab]
  );

  if (buyItAgainError) {
    console.error("Error fetching user's previously purchased products.", buyItAgainError);
  }

  const buyItAgainProductCount = buyItAgainProducts.length;
  const showBuyItAgainProducts = buyItAgainProductsEnabled && !buyItAgainLoading && buyItAgainProductCount > 0;
  const isLoading = products.length && buyItAgainLoading;

  if (error || !products.length) {
    return null;
  }

  return (
    <StyledCarouselContainer data-testid='personalized-products-carousel' {...theme} border fullWidth margin={margin}>
      <EmptyLoadingState page='personalizedCarousel' isLoading={isLoading} tabbedHeader={buyItAgainProductsEnabled}>
        <Content>
          <HybridHeader carouselTheme={theme} heading={heading} icon={icon} subheading={subheading} />
          {showBuyItAgainProducts ? (
            <StyledTabContext value={selectedTab}>
              <StyledTabs
                defaultValue={TABS.TOP_PICKS}
                value={selectedTab}
                onChange={handleTabChange}
                selectionFollowsFocus
              >
                <StyledTab label='Top picks' {...TabProps(TABS.TOP_PICKS, selectedTab)} />
                <StyledTab label='Buy it again' {...TabProps(TABS.BUY_IT_AGAIN, selectedTab)} />
              </StyledTabs>
              <StyledTabPanel {...TabPanelProps(TABS.TOP_PICKS)} ref={topPicksRef}>
                {selectedTab === TABS.TOP_PICKS && (
                  <TabSlider
                    products={products}
                    onProductClick={handleProductClick}
                    onQuickAddToCart={handleQuickAddToCart}
                    onView={handleProductImpression}
                  />
                )}
              </StyledTabPanel>
              <StyledTabPanel {...TabPanelProps(TABS.BUY_IT_AGAIN)} ref={buyItAgainRef}>
                {selectedTab === TABS.BUY_IT_AGAIN && (
                  <TabSlider
                    products={buyItAgainProducts}
                    onProductClick={handleBuyItAgainProductClick}
                    onQuickAddToCart={handleBuyItAgainQuickAddToCart}
                    onView={handleProductImpression}
                    viewAllCard={<BuyItAgainViewAll handleViewAllClick={handleViewAllClick} />}
                  />
                )}
              </StyledTabPanel>
            </StyledTabContext>
          ) : (
            <TabSlider
              products={products}
              onProductClick={handleProductClick}
              onQuickAddToCart={handleQuickAddToCart}
              onView={handleProductImpression}
            />
          )}
        </Content>
      </EmptyLoadingState>
    </StyledCarouselContainer>
  );
};
