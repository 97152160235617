import React from 'react';
import { Product } from 'src/utils/helpers/product';
import { ApolloError } from 'apollo-client';

import { ViewportVisibility } from 'src/hooks/use-viewport-visibility';

import type { Theme } from '../../../types';

import { BuyItAgainList, BuyItAgainListItem } from './buy-it-again-list';

type BuyItAgainProps = {
  error?: ApolloError;
  handleProductClick: (product: Product, index: number) => void;
  handleQuickAddToCart: (product: Product, index: number) => void;
  handleViewAllClick: (destinationUrl: string) => void;
  products: Product[];
  theme: Theme;
  viewportVisibility: ViewportVisibility;
};

export const BuyItAgain = ({
  error,
  handleProductClick,
  handleQuickAddToCart,
  handleViewAllClick,
  products,
  theme,
  viewportVisibility,
}: BuyItAgainProps): JSX.Element | null => {
  const { backgroundColor } = theme;
  const { ref } = viewportVisibility;

  if (error || !products.length) {
    return null;
  }

  return (
    <BuyItAgainList
      productCount={products.length}
      backgroundColor={backgroundColor}
      handleViewAllClick={handleViewAllClick}
      ref={ref}
    >
      {products.map((product, index) => (
        <BuyItAgainListItem
          key={product.id}
          product={product}
          productIndex={index}
          onClick={() => handleProductClick(product, index)}
          onQuickAddClick={() => handleQuickAddToCart(product, index)}
        />
      ))}
    </BuyItAgainList>
  );
};
